import React from "react";
import Layout from "../components/layout";

const Organizers = () => (
  <Layout>
    <div>Hi</div>
  </Layout>
);

export default Organizers;
